import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import {
  BulkUpdateLeadManagementRule,
  FieldsToUpdateInRule
} from '../../../types/data/lead-management.type';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Switch,
  Tooltip,
  Typography,
  message
} from 'antd';
import React, { useState } from 'react';

import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { PageHeader } from '@ant-design/pro-layout';

type BulkEditLeadManagementModalProps = {
  handleOk: (data: any) => void;
  handleCancel: (event: any) => void;
  salesforceFields: any;
};

const BulkEditLeadManagementModal = (
  props: BulkEditLeadManagementModalProps
) => {
  const formModalRef: React.RefObject<FormInstance> =
    React.createRef<FormInstance>();

  const data: BulkUpdateLeadManagementRule = {
    rule: {},
    fieldsToUpdate: []
  } as BulkUpdateLeadManagementRule;

  const [leadJourney, setLeadJourney] = useState<CheckboxValueType[]>([]);

  const handleFormSubmit = () => {
    formModalRef.current
      ?.validateFields()
      .then(updates => {
        if (updates.fieldsToUpdate.length == 0) {
          throw new Error('Please select atleast 1 field to update');
        }
        const dataToBeSubmitted = { ...data, ...updates };
        props.handleOk(dataToBeSubmitted);
      })
      .catch(error => {
        message.warning(error.message ?? 'Fill all the required fields.');
      });
  };

  function restrictToNumericInput(e: React.KeyboardEvent<HTMLInputElement>) {
    if (
      !/^\d$/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight'
    ) {
      e.preventDefault();
    }
  }

  const handleFieldsToUpdate = (checked: boolean, fieldName: string) => {
    let value: FieldsToUpdateInRule =
      formModalRef.current?.getFieldValue('fieldsToUpdate');
    if (checked) {
      value = [...value, fieldName] as FieldsToUpdateInRule;
    } else {
      value = value.filter((value: string) => value !== fieldName);
    }
    formModalRef.current?.setFieldValue('fieldsToUpdate', value);
  };

  return (
    <>
      <PageHeader
        className='space-top-10'
        ghost={false}
        title={
          <>
            <ArrowLeftOutlined
              style={{ marginRight: '5px' }}
              onClick={props.handleCancel}
            />
            Bulk Edit Rules
          </>
        }
        style={{ background: '#ffffff' }}>
        <Form
          ref={formModalRef}
          initialValues={{ statusActive: false, ...data }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '5px',
              marginTop: '-5px',
              marginLeft: '20px'
            }}>
            <Typography.Title level={5}>
              <Form.Item name={['fieldsToUpdate']} noStyle>
                <Checkbox
                  onChange={e => {
                    handleFieldsToUpdate(e.target.checked, 'statusActive');
                  }}
                />
              </Form.Item>{' '}
              Status
            </Typography.Title>
          </div>
          <div
            style={{
              width: '60%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '-25px',
              marginTop: '7px',
              marginLeft: '20px'
            }}>
            <div style={{ flex: '1 1 5%', marginRight: '10px' }}>
              <Form.Item
                name={['rule', 'statusActive']}
                label='Active'
                valuePropName='checked'
                initialValue={false}>
                <Switch
                  style={{ flex: 1, marginRight: '10px', width: '10%' }}
                />
              </Form.Item>
            </div>
          </div>
          <Divider />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '5px',
              marginTop: '-5px',
              marginLeft: '20px'
            }}>
            <Typography.Title level={5}>
              {' '}
              <Form.Item name={['fieldsToUpdate']} noStyle>
                <Checkbox
                  onChange={e => {
                    handleFieldsToUpdate(e.target.checked, 'threshold');
                  }}
                />
              </Form.Item>{' '}
              Threshold
            </Typography.Title>
            <Tooltip title="Define the percentage of leads whose users' journey should follow the rule defined.">
              <QuestionCircleOutlined
                style={{
                  color: '#00000073',
                  marginLeft: '5px',
                  marginBottom: '5px'
                }}
              />
            </Tooltip>
          </div>
          <div
            style={{
              width: '40%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '-25px',
              marginLeft: '20px'
            }}>
            <Form.Item
              name={['rule', 'threshold']}
              label='Percentage'
              initialValue={0}>
              <InputNumber
                min={0}
                max={100}
                onKeyDown={restrictToNumericInput}
              />
            </Form.Item>
          </div>
          <Divider />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '5px',
              marginTop: '-5px',
              marginLeft: '20px'
            }}>
            <Typography.Title level={5}>
              {' '}
              <Form.Item name={['fieldsToUpdate']} noStyle>
                <Checkbox
                  onChange={e => {
                    handleFieldsToUpdate(e.target.checked, 'leadJourney');
                  }}
                />
              </Form.Item>{' '}
              Lead Journey
            </Typography.Title>
            <Tooltip title='Define the journey of the lead that satisfies the rule and falls under the threshold.'>
              <QuestionCircleOutlined
                style={{
                  color: '#00000073',
                  marginLeft: '5px',
                  marginBottom: '5px'
                }}
              />
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '-25px',
              marginLeft: '20px'
            }}>
            <Form.Item
              name={['rule', 'leadJourney']}
              label='Flow'
              rules={[
                {
                  required: false
                }
              ]}>
              <Checkbox.Group
                options={[
                  'Address Page',
                  'Medicare 101',
                  'Quoting Tool',
                  'Redirect'
                ]}
                onChange={e => {
                  let currentJourney = e;
                  if (
                    currentJourney.includes('Redirect') &&
                    !leadJourney.includes('Redirect')
                  ) {
                    currentJourney = ['Redirect'];
                  } else if (
                    (currentJourney.includes('Address Page') ||
                      currentJourney.includes('Medicare 101') ||
                      currentJourney.includes('Quoting Tool')) &&
                    leadJourney.includes('Redirect')
                  ) {
                    currentJourney = currentJourney.filter(
                      item => item !== 'Redirect'
                    );
                    formModalRef.current?.setFieldValue(
                      ['rule', 'redirectTo'],
                      undefined
                    );
                  }
                  formModalRef.current?.setFieldValue(
                    ['rule', 'leadJourney'],
                    currentJourney
                  );
                  setLeadJourney(currentJourney);
                }}
              />
            </Form.Item>
          </div>
          {leadJourney.includes('Redirect') && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '-25px',
                marginLeft: '20px'
              }}>
              <Form.Item
                name={['rule', 'redirectTo']}
                label='Redirect To'
                rules={[
                  {
                    required: true
                  }
                ]}>
                <Input.TextArea rows={1} style={{ resize: 'none' }} />
              </Form.Item>
            </div>
          )}
          <Divider />
          <Form.List name={['rule', 'salesforceFields']} initialValue={['']}>
            {(fields, { add, remove }) => (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '5px',
                    marginTop: '-5px',
                    marginLeft: '20px'
                  }}>
                  <Typography.Title level={5}>
                    <Form.Item name={['fieldsToUpdate']} noStyle>
                      <Checkbox
                        onChange={e => {
                          handleFieldsToUpdate(
                            e.target.checked,
                            'salesforceFields'
                          );
                        }}
                      />
                    </Form.Item>{' '}
                    Salesforce fields to be updated
                  </Typography.Title>
                  <Tooltip title='Define the Salesforce fields and their corresponding values that need to be updated on lead creation for the leads that satisfy the rule and fall under the threshold.'>
                    <QuestionCircleOutlined
                      style={{
                        color: '#00000073',
                        marginLeft: '5px',
                        marginBottom: '5px'
                      }}
                    />
                  </Tooltip>
                  <Button
                    type='primary'
                    onClick={() => {
                      add();
                    }}
                    style={{ marginLeft: 'auto' }}>
                    <PlusOutlined /> Edit Field
                  </Button>
                </div>
                {fields.map(({ key, name, ...restField }) => (
                  <div
                    key={key}
                    style={{
                      width: '60%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: '20px',
                      marginTop: '10px'
                    }}>
                    <div style={{ flex: '1 1 30%', marginRight: '10px' }}>
                      <Form.Item
                        {...restField}
                        name={[name, 'field']}
                        fieldKey={['field']}
                        label='Field'>
                        <Select
                          allowClear
                          showSearch
                          options={[...props.salesforceFields]}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ flex: '1 1 25%', marginRight: '10px' }}>
                      <Form.Item
                        {...restField}
                        name={[name, 'value']}
                        fieldKey={['value']}
                        label='Value'>
                        <Input.TextArea rows={1} style={{ resize: 'none' }} />
                      </Form.Item>
                    </div>
                    {fields.length > 0 && (
                      <MinusCircleOutlined
                        style={{
                          color: 'red',
                          fontSize: '20px',
                          marginBottom: '20px'
                        }}
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    )}
                  </div>
                ))}
              </>
            )}
          </Form.List>
          <Divider />
          <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={props.handleCancel}>Cancel</Button>
            <Button
              type='primary'
              onClick={handleFormSubmit}
              style={{ marginLeft: '10px' }}>
              Bulk Update
            </Button>
          </Form.Item>
        </Form>
      </PageHeader>
    </>
  );
};

export default BulkEditLeadManagementModal;
