import {
  BulkUpdateLeadManagementRule,
  BulkUpdateLeadManagementRuleRequest,
  LeadManagementType
} from '../types/data/lead-management.type';

import { ApiUtils } from '../utils/api.utils';
import { UrlConstants } from '../constants/url.constants';
import axios from 'axios';

const getRules = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.GET_RULES;
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const deleteRule = (ruleId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.DELETE_RULE.replaceAll(':ruleId', ruleId);
    axios
      .delete(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => reject(err));
  });
};

const getSalesforceFields = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.GET_SF_FIELDS;
    axios
      .get(url, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => {
        reject(err?.response?.data || err);
      });
  });
};

const updateRule = (data: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.UPDATE_RULE.replaceAll(':ruleId', data.id);
    axios
      .patch(url, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) resolve(response.data.data);
        else throw response;
      })
      .catch(err => {
        reject(err?.response?.data || err);
      });
  });
};

const addRule = (data: any): Promise<LeadManagementType> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.ADD_RULE;
    axios
      .post(url, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          const result: LeadManagementType = response.data.data;
          result.leadJourney = [
            result.addressPage ? 'Address Page' : 'false',
            result.medicare101Page ? 'Medicare 101' : 'false',
            result.quotingToolPage ? 'Quoting Tool' : 'false',
            result.redirect ? 'Redirect' : 'false'
          ];
          result.statusActive = result.status === 'active' ? true : false;
          resolve(result);
        } else throw response;
      })
      .catch(err => {
        reject(err?.response?.data || err);
      });
  });
};

const bulkUpdateRules = (
  data: BulkUpdateLeadManagementRuleRequest
): Promise<LeadManagementType[]> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.BULK_UPDATE_RULE;
    axios
      .post(url, data, ApiUtils.getHeaders())
      .then(response => {
        if (response.status === 200) {
          const result: LeadManagementType[] = response.data.data;
          result.forEach(rule => {
            rule.leadJourney = [
              rule.addressPage ? 'Address Page' : 'false',
              rule.medicare101Page ? 'Medicare 101' : 'false',
              rule.quotingToolPage ? 'Quoting Tool' : 'false',
              rule.redirect ? 'Redirect' : 'false'
            ];
            rule.statusActive = rule.status === 'active' ? true : false;
          });
          resolve(result);
        } else throw response;
      })
      .catch(err => {
        reject(err?.response?.data || err);
      });
  });
};

const updatePriorities = (priority: {
  id: string;
  priority: number;
  existingPriority: number;
}): Promise<LeadManagementType> => {
  return new Promise((resolve, reject) => {
    const url = UrlConstants.UPDATE_PRIORITIES;
    axios
      .patch(url, { priorities: priority }, ApiUtils.getHeaders())
      .then(response => {
        resolve(response.data.data);
      })
      .catch(err => {
        reject(err?.response?.data || err);
      });
  });
};

const cleanRuleData = (data: any, priority?: number) => {
  const cleanedData: LeadManagementType = {} as LeadManagementType;
  cleanedData.id = data.id;
  cleanedData.name = data.name;
  cleanedData.priority = data.priority || priority;
  cleanedData.status = data.statusActive ? 'active' : 'inactive';
  cleanedData.threshold = data.threshold;
  cleanedData.ruleCondition = data.ruleCondition;
  const filteredSalesforceFields = data.salesforceFields
    .filter((item: any) => item.field !== undefined)
    .map((item: any) => ({
      field: item.field,
      value: item.value !== undefined ? item.value : ''
    }));

  if (filteredSalesforceFields && filteredSalesforceFields[0] !== '') {
    cleanedData.salesforceFields = filteredSalesforceFields;
  }
  cleanedData.logic = data.logic && data.logic === 'OR' ? data.logic : 'AND';
  cleanedData.addressPage = data.leadJourney.includes('Address Page');
  cleanedData.medicare101Page = data.leadJourney.includes('Medicare 101');
  cleanedData.quotingToolPage = data.leadJourney.includes('Quoting Tool');
  cleanedData.redirect = data.leadJourney.includes('Redirect');
  if (cleanedData.redirect) {
    cleanedData.redirectTo = data.redirectTo;
  }
  cleanedData.leadJourney = data.leadJourney;
  cleanedData.statusActive = data.statusActive;

  return cleanedData;
};

const getBulkUpdateRulesRequestBody = (
  data: BulkUpdateLeadManagementRule,
  ids: string[]
) => {
  const cleanedData: BulkUpdateLeadManagementRuleRequest = {
    ids
  };

  const { fieldsToUpdate } = data;

  if (fieldsToUpdate.includes('statusActive' as never)) {
    cleanedData.status = data.rule.statusActive ? 'active' : 'inactive';
  }
  if (fieldsToUpdate.includes('threshold' as never)) {
    cleanedData.threshold = data.rule.threshold;
  }
  if (fieldsToUpdate.includes('salesforceFields' as never)) {
    const filteredSalesforceFields = data.rule.salesforceFields
      ?.filter((item: any) => item.field !== undefined)
      .map((item: any) => ({
        field: item.field,
        value: item.value !== undefined ? item.value : ''
      }));
    if (filteredSalesforceFields && filteredSalesforceFields.length > 0) {
      cleanedData.salesforceFields = filteredSalesforceFields;
    }
  }
  if (fieldsToUpdate.includes('leadJourney' as never)) {
    cleanedData.addressPage =
      data.rule.leadJourney?.includes('Address Page') || false;
    cleanedData.medicare101Page =
      data.rule.leadJourney?.includes('Medicare 101') || false;
    cleanedData.quotingToolPage =
      data.rule.leadJourney?.includes('Quoting Tool') || false;
    cleanedData.redirect = data.rule.leadJourney?.includes('Redirect') || false;
    if (cleanedData.redirect) {
      cleanedData.redirectTo = data.rule.redirectTo;
    }
  }
  return cleanedData;
};

export const LeadManagementService = {
  getRules,
  deleteRule,
  getSalesforceFields,
  updateRule,
  bulkUpdateRules,
  addRule,
  cleanRuleData,
  getBulkUpdateRulesRequestBody,
  updatePriorities
};
