import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { LeadManagementType } from '../../types/data/lead-management.type';

/**
 * get columns of reports
 * @returns {ColumnsType<LeadManagementType>}
 */
export const getLeadManagementColumns = (
  handleEdit: any,
  handleRemove: any,
  disabled = false
): ColumnsType<LeadManagementType> => [
  {
    title: 'Rule Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '13%',
    render: name => <span style={{ textTransform: 'capitalize' }}>{name}</span>
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    width: '13%',
    sorter: (a, b) => a.priority - b.priority,
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '13%',
    sorter: (a, b) => a.status.localeCompare(b.status),
    align: 'center',
    render: status => (
      <span style={{ textTransform: 'capitalize' }}>{status}</span>
    )
  },
  {
    title: 'Threshold Percentage',
    dataIndex: 'threshold',
    key: 'threshold',
    width: '13%',
    sorter: (a, b) => a.threshold - b.threshold,
    align: 'center'
  },
  {
    title: 'Lead Journey',
    dataIndex: 'leadJourney',
    key: 'leadJourney',
    align: 'center',
    render: leadJourney =>
      leadJourney.filter((page: string) => page).join(' → '),
    width: '13%'
  },
  {
    title: 'Salesforce Fields',
    dataIndex: 'salesforceFields',
    key: 'salesforceFields',
    align: 'center',
    sorter: (a, b) => {
      if (a.salesforceFields && b.salesforceFields) {
        const aFields = (a.salesforceFields as any[])
          .map(data => data.field)
          .join(', ');
        const bFields = (b.salesforceFields as any[])
          .map(data => data.field)
          .join(', ');
        return aFields.localeCompare(bFields);
      } else {
        return 0;
      }
    },
    render: salesforceFields => {
      if (salesforceFields) {
        return (salesforceFields as any[]).map(data => data.field).join(', ');
      } else {
        return '';
      }
    },
    width: '13%'
  },
  {
    title: '',
    key: 'id',
    render: data => (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gridGap: '5px',
          justifyContent: 'center'
        }}>
        <Button
          type='primary'
          disabled={disabled}
          onClick={() => handleEdit(data)}>
          <EditOutlined /> Edit
        </Button>
        <Button
          style={{
            backgroundColor: '#FF4D4F',
            color: disabled ? '#00000025' : 'white'
          }}
          type='default'
          disabled={disabled}
          onClick={() => handleRemove(data)}>
          <DeleteOutlined /> Remove
        </Button>
      </div>
    ),
    align: 'center'
  }
];
