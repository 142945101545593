import { Layout } from 'antd';

const Footer: React.FC = () => {
  return (
    <Layout.Footer className='footer'>
      <p>
        EIP Quoting Tool SA Dashboard ©{new Date().getFullYear()} by FoxSense
        Innovations
      </p>
    </Layout.Footer>
  );
};

export default Footer;
