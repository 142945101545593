import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Tooltip,
  Typography,
  message
} from 'antd';
import React, { useState } from 'react';

import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { LeadManagementType } from '../../../types/data/lead-management.type';
import LoadingComponent from '../../../components/loading';
import { PageHeader } from '@ant-design/pro-layout';
import { StringConstants } from '../../../constants/string.constants';
import isUndefined from 'lodash.isundefined';

type EditLeadManagementModalProps = {
  handleOk: (data: any) => void;
  handleCancel: (event: any) => void;
  data: LeadManagementType;
  rulesData: LeadManagementType[];
  salesforceFields: any;
};

const EditLeadManagementModal = (props: EditLeadManagementModalProps) => {
  const formModalRef: React.RefObject<FormInstance> =
    React.createRef<FormInstance>();
  const [conditionLength, setConditionLength] = useState(
    props?.data?.ruleCondition?.length || 1
  );

  const [loading] = React.useState<boolean>(false);
  const [loadingTip] = React.useState<string>('');
  const [leadJourney, setLeadJourney] = useState<CheckboxValueType[]>(
    props.data.leadJourney || []
  );

  const handleFormSubmit = () => {
    formModalRef.current
      ?.validateFields()
      .then(data => {
        const dataToBeSubmitted = { ...props.data, ...data };
        if (data?.ruleCondition[0]?.logic) {
          dataToBeSubmitted.logic = data?.ruleCondition[0]?.logic;
        }
        props.handleOk(dataToBeSubmitted);
      })
      .catch(() => {
        message.warning('Fill all the required fields.');
      });
  };

  const ruleNames = props.rulesData.map(rule => rule.name.toLowerCase());
  const rulePriorities = props.rulesData.map(rule => rule.priority);
  const updateConditionLength = () => {
    console.log(
      'Length:',
      formModalRef.current?.getFieldValue('ruleCondition').length
    );
    setConditionLength(
      formModalRef.current?.getFieldValue('ruleCondition').length
    );
  };

  function restrictToNumericInput(e: React.KeyboardEvent<HTMLInputElement>) {
    if (
      !/^\d$/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight'
    ) {
      e.preventDefault();
    }
  }

  if (props.data) {
    if (!props.data.leadJourney) {
      props.data.leadJourney = ['Address Page', 'Medicare 101', 'Quoting Tool'];
    }
    if (isUndefined(props.data.statusActive)) props.data.statusActive = true;
    return (
      <>
        {!loading ? (
          <PageHeader
            className='space-top-10'
            ghost={false}
            title={
              <>
                <ArrowLeftOutlined
                  style={{ marginRight: '5px' }}
                  onClick={props.handleCancel}
                />
                {props.data.id ? 'Edit Rule' : 'Add New Rule'}
              </>
            }
            style={{ background: '#ffffff' }}>
            <Form
              ref={formModalRef}
              initialValues={{ statusActive: false, ...props.data }}>
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '-25px',
                  marginTop: '7px',
                  marginLeft: '20px'
                }}>
                <div style={{ flex: '1 1 45%', marginRight: '10px' }}>
                  <Form.Item
                    name='name'
                    label='Rule Name'
                    rules={[
                      {
                        required: true,
                        message: 'Please provide a valid value'
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (
                            ruleNames.includes(value.toLowerCase()) &&
                            !props.data.id
                          ) {
                            return Promise.reject(
                              'This rule name already exists.'
                            );
                          } else if (
                            ruleNames.includes(value.toLowerCase()) &&
                            props.data.id &&
                            value.toLowerCase() !==
                              props.data.name.toLowerCase()
                          ) {
                            return Promise.reject(
                              'This rule name already exists.'
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                      })
                    ]}>
                    <Input.TextArea rows={1} style={{ resize: 'none' }} />
                  </Form.Item>
                </div>
                <div style={{ flex: '1 1 5%', marginRight: '10px' }}>
                  <Form.Item
                    name='priority'
                    label='Priority'
                    rules={[
                      {
                        required: false,
                        message: 'Please provide a valid value'
                      },
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (
                            rulePriorities.includes(value) &&
                            !props.data.id
                          ) {
                            return Promise.reject(
                              'This priority already exists.'
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                      })
                    ]}>
                    <InputNumber
                      min={0}
                      style={{ width: '100%' }}
                      onKeyDown={restrictToNumericInput}
                    />
                  </Form.Item>
                </div>
                <div style={{ flex: '1 1 5%', marginRight: '10px' }}>
                  <Form.Item
                    name='statusActive'
                    label='Active'
                    valuePropName='checked'>
                    <Switch
                      style={{ flex: 1, marginRight: '10px', width: '10%' }}
                    />
                  </Form.Item>
                </div>
              </div>
              <Divider />
              <Form.Item>
                <Form.List name='ruleCondition' initialValue={['']}>
                  {(fields, { add, remove }) => {
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '5px',
                            marginTop: '-5px',
                            marginLeft: '20px'
                          }}>
                          <Typography.Title level={5}>
                            Condition
                          </Typography.Title>
                          <Tooltip title='Define the conditions for salesforce fields that have to be satisfied for the rule to be applied to the lead journey.'>
                            <QuestionCircleOutlined
                              style={{
                                color: '#00000073',
                                marginLeft: '5px',
                                marginBottom: '5px'
                              }}
                            />
                          </Tooltip>
                          <Button
                            type='primary'
                            onClick={() => {
                              add();
                              updateConditionLength();
                            }}
                            style={{ marginLeft: 'auto' }}>
                            <PlusOutlined /> Add Condition
                          </Button>
                        </div>
                        {conditionLength > 1 && (
                          <>
                            {fields.map(
                              ({ key, name, ...restField }, index) => {
                                if (index === 0)
                                  return (
                                    <div
                                      key={key}
                                      style={{
                                        marginLeft: '20px',
                                        marginTop: '5px'
                                      }}>
                                      <Form.Item
                                        name={[name, 'logic']}
                                        label='Logic'
                                        required
                                        {...restField}>
                                        <Radio.Group
                                          options={['AND', 'OR']}
                                          defaultValue={props?.data?.logic}
                                        />
                                      </Form.Item>
                                    </div>
                                  );
                              }
                            )}
                          </>
                        )}
                        {fields.map(({ key, name, ...restField }) => (
                          <div
                            key={key}
                            style={{
                              width: '80%',
                              display: 'flex',
                              flexDirection: 'row',
                              marginLeft: '20px',
                              marginTop: '5px'
                            }}>
                            <div
                              style={{ flex: '1 1 30%', marginRight: '10px' }}>
                              <Form.Item
                                {...restField}
                                name={[name, 'field']}
                                label='Field'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please provide a valid value'
                                  }
                                ]}>
                                <Select
                                  allowClear
                                  showSearch
                                  options={[...props.salesforceFields]}
                                />
                              </Form.Item>
                            </div>
                            <div
                              style={{ flex: '1 1 25%', marginRight: '10px' }}>
                              <Form.Item
                                {...restField}
                                name={[name, 'operation']}
                                label='Operation'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please provide a valid value'
                                  }
                                ]}>
                                <Select
                                  allowClear
                                  showSearch
                                  options={[...StringConstants.OPERATIONS]}
                                />
                              </Form.Item>
                            </div>
                            <div
                              style={{ flex: '1 1 25%', marginRight: '10px' }}>
                              <Form.Item
                                {...restField}
                                name={[name, 'value']}
                                label='Value'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please provide a valid value'
                                  }
                                ]}>
                                <Input.TextArea
                                  rows={1}
                                  style={{ resize: 'none' }}
                                />
                              </Form.Item>
                            </div>
                            {fields.length > 1 && (
                              <MinusCircleOutlined
                                style={{
                                  color: 'red',
                                  fontSize: '20px',
                                  marginBottom: '20px'
                                }}
                                onClick={() => {
                                  remove(name);
                                  updateConditionLength();
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </>
                    );
                  }}
                </Form.List>
              </Form.Item>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '5px',
                  marginTop: '-5px',
                  marginLeft: '20px'
                }}>
                <Typography.Title level={5}>Threshold</Typography.Title>
                <Tooltip title="Define the percentage of leads whose users' journey should follow the rule defined.">
                  <QuestionCircleOutlined
                    style={{
                      color: '#00000073',
                      marginLeft: '5px',
                      marginBottom: '5px'
                    }}
                  />
                </Tooltip>
              </div>
              <div
                style={{
                  width: '40%',
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '-25px',
                  marginLeft: '20px'
                }}>
                <Form.Item
                  name='threshold'
                  label='Percentage'
                  rules={[
                    {
                      required: true,
                      message: 'Please provide a valid value'
                    }
                  ]}>
                  <InputNumber
                    min={0}
                    max={100}
                    onKeyDown={restrictToNumericInput}
                  />
                </Form.Item>
              </div>
              <Divider />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '5px',
                  marginTop: '-5px',
                  marginLeft: '20px'
                }}>
                <Typography.Title level={5}>Lead Journey</Typography.Title>
                <Tooltip title='Define the journey of the lead that satisfies the rule and falls under the threshold.'>
                  <QuestionCircleOutlined
                    style={{
                      color: '#00000073',
                      marginLeft: '5px',
                      marginBottom: '5px'
                    }}
                  />
                </Tooltip>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '-25px',
                  marginLeft: '20px'
                }}>
                <Form.Item
                  name='leadJourney'
                  label='Flow'
                  rules={[
                    {
                      required: false
                    }
                  ]}>
                  <Checkbox.Group
                    options={[
                      'Address Page',
                      'Medicare 101',
                      'Quoting Tool',
                      'Redirect'
                    ]}
                    onChange={e => {
                      let currentJourney = e;
                      if (
                        currentJourney.includes('Redirect') &&
                        !leadJourney.includes('Redirect')
                      ) {
                        currentJourney = ['Redirect'];
                      } else if (
                        (currentJourney.includes('Address Page') ||
                          currentJourney.includes('Medicare 101') ||
                          currentJourney.includes('Quoting Tool')) &&
                        leadJourney.includes('Redirect')
                      ) {
                        currentJourney = currentJourney.filter(
                          item => item !== 'Redirect'
                        );
                        formModalRef.current?.setFieldValue(
                          'redirectTo',
                          undefined
                        );
                      }
                      formModalRef.current?.setFieldValue(
                        'leadJourney',
                        currentJourney
                      );
                      setLeadJourney(currentJourney);
                    }}
                  />
                </Form.Item>
              </div>
              {leadJourney.includes('Redirect') && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '-25px',
                    marginLeft: '20px'
                  }}>
                  <Form.Item
                    name='redirectTo'
                    label='Redirect To'
                    rules={[
                      {
                        required: true
                      }
                    ]}>
                    <Input.TextArea rows={1} style={{ resize: 'none' }} />
                  </Form.Item>
                </div>
              )}
              <Divider />
              <Form.List name='salesforceFields' initialValue={['']}>
                {(fields, { add, remove }) => (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '5px',
                        marginTop: '-5px',
                        marginLeft: '20px'
                      }}>
                      <Typography.Title level={5}>
                        Salesforce fields to be updated
                      </Typography.Title>
                      <Tooltip title='Define the Salesforce fields and their corresponding values that need to be updated on lead creation for the leads that satisfy the rule and fall under the threshold.'>
                        <QuestionCircleOutlined
                          style={{
                            color: '#00000073',
                            marginLeft: '5px',
                            marginBottom: '5px'
                          }}
                        />
                      </Tooltip>
                      <Button
                        type='primary'
                        onClick={() => {
                          add();
                        }}
                        style={{ marginLeft: 'auto' }}>
                        <PlusOutlined /> Edit Field
                      </Button>
                    </div>
                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        key={key}
                        style={{
                          width: '60%',
                          display: 'flex',
                          flexDirection: 'row',
                          marginLeft: '20px',
                          marginTop: '10px'
                        }}>
                        <div style={{ flex: '1 1 30%', marginRight: '10px' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'field']}
                            fieldKey={['field']}
                            label='Field'>
                            <Select
                              allowClear
                              showSearch
                              options={[...props.salesforceFields]}
                            />
                          </Form.Item>
                        </div>
                        <div style={{ flex: '1 1 25%', marginRight: '10px' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'value']}
                            fieldKey={['value']}
                            label='Value'>
                            <Input.TextArea
                              rows={1}
                              style={{ resize: 'none' }}
                            />
                          </Form.Item>
                        </div>
                        {fields.length > 0 && (
                          <MinusCircleOutlined
                            style={{
                              color: 'red',
                              fontSize: '20px',
                              marginBottom: '20px'
                            }}
                            onClick={() => {
                              remove(name);
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
              <Divider />
              <Form.Item
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={props.handleCancel}>Cancel</Button>
                <Button
                  type='primary'
                  onClick={handleFormSubmit}
                  style={{ marginLeft: '10px' }}>
                  {props.data.id ? 'Update Rule' : 'Add New Rule'}
                </Button>
              </Form.Item>
            </Form>
          </PageHeader>
        ) : (
          <LoadingComponent tip={loadingTip} />
        )}
      </>
    );
  } else return null;
};

export default EditLeadManagementModal;
